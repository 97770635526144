<template>
  <div class="">
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">CALENDAR</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-dialog
        :visible.sync="dialogVisible"
        width="75%">
        <div class="flex gaps column">
          <div class="flex gaps">
            <div class="box self-flex-end"><h2>{{dialogTitle}}</h2></div>
          </div>
          <div class="flex gaps wrap">
            <div class="box pb-16" v-for="(t, index) in templates" :key="index">
              <div class="box template-cell clickable border" @click="setEvent(dialogTitle, t._id)"
                v-bind:style="{ backgroundColor: t.color}">
                  <div class="m-8 pr-12 pt-8">{{t.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
        </span>
    </el-dialog>
    <FullCalendar :options="calendarOptions" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data () {
    return {
      dialogTitle: '',
      dialogVisible: false,
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        events: [],
        height: 580,
        customButtons: {
          myCustomButton: {
            text: 'Templates',
            click: this.gotoTemplates
          }
        },
        headerToolbar: {
          left: 'myCustomButton',
          center: 'title',
          right: 'prev next today'
        },
        dateClick: this.dayClick
      }
    }
  },

  computed: {
    ...mapState({
      templates: state => state.templates.templates,
      calendarEvents: state => state.calendar.calendar
    })
  },
  methods: {
    gotoTemplates () {
      this.$onCommandParams('templates')
    },
    dayClick (arg) {
      this.dialogVisible = true
      this.dialogTitle = arg.dateStr
    },
    setEvent (eventDate, id) {
      this.$store.dispatch('updateDate', {
        eventDate: eventDate,
        content: {
          date: eventDate,
          templateId: id
        }
      }).then(() => {
        this.dialogVisible = false
      })
    },
    setCalendarEvents () {
      this.calendarOptions.events = this.calendarEvents.map(c => {
        return { title: c.name,
          start: c.day,
          backgroundColor: c.color,
          display: 'background'
        }
      })
    }
  },
  created () {
    this.setCalendarEvents()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'UPDATE_DATE') {
        let t = this.templates.find(el => el._id === mutation.payload.content.templateId)
        if (this.calendarOptions.events.some(el => el.start === mutation.payload.eventDate)) {
          this.calendarOptions.events.forEach(c => {
            if (c.start === mutation.payload.eventDate) {
              Object.assign(c, {
                title: t.name,
                backgroundColor: t.color
              })
            }
          })
        } else {
          this.calendarOptions.events.splice(this.calendarOptions.events.length, 0,
            { title: t.name,
              start: mutation.payload.eventDate,
              backgroundColor: t.color,
              display: 'background'
            })
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>

<style>
.fc .fc-bg-event {
    opacity: 1;
}
.fc .fc-view-harness a {
    color: white;
}
.fc .fc-bg-event .fc-event-title {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
}
.el-dialog {
  background-color: #232933;
  color: white;
}
.el-dialog__body {
    padding: 30px 20px;
    color: white;
    font-size: 14px;
    word-break: break-all;
}
.el-dialog__title {
    line-height: 24px;
    font-size: 24px;
    color: white;
}
.el-dialog__header {
    padding: 0;
}
.fc-event-today {
  background-image:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNHB4IiBoZWlnaHQ9IjRweCIgdmlld0JveD0iMCAwIDQgNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNCA0IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0FCQUJBQiIgc3Ryb2tlLXdpZHRoPSIwLjUiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgeDE9IjAiIHkxPSIwIiB4Mj0iNCIgeTI9IjQiLz4NCjwvc3ZnPg0K") !important;
  background-repeat:repeat !important;
  opacity: 0.8 !important;
  background-position:0 0 !important;
  background-size:4px 4px !important;
}
</style>
